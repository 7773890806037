module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CXF05H4F0J"],"gtagConfig":{"optimize_id":"OPT-PFV9F5M","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":false,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Colorbase","short_name":"Colorbase","description":"Convert color values from HEX to RGB, RAL, HSL, HSB, CMYK, PANTONE, NCS, NTC, WEBSAFE and vice versa. Find color schemes and generate color palettes. Explore the color charts.","start_url":"/","background_color":"#252525","theme_color":"#ffc107","display":"minimal-ui","icon":"src/images/colorbase-logo-design-icon2.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3f56ead7264a9bce58980052014973a7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
